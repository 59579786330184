<template>
	<Transition
		name="slide-left"
		mode="out-in"
	>
		<EditableItemsItemEditor
			v-if="isInputShown"
			:value="inputValue"
			class="add-button-input"
			:placeholder="placeholder"
			:error="error"
			@input="handleInput"
			@submit="submitValue"
			@blur="hideInput"
		/>
		<div
			v-else
			class="add-button-container"
		>
			<slot
				name="button"
				:show-input="showInput"
			>
				<ZyroButton
					class="add-button"
					icon-left="plus-circle"
					data-qa="edititems-list-btn-additem"
					@click="isInputVisibleOnAddClick && showInput()"
				>
					{{ buttonText }}
				</ZyroButton>
			</slot>
			<ZyroSeparator />
		</div>
	</Transition>
</template>

<script>
import {
	useEditableItemInput,
	defaultValidator,
} from '@/components/reusable-components/editable-items-list/useEditableItemInput';

import EditableItemsItemEditor from './EditableItemsItemEditor.vue';

export default {
	components: { EditableItemsItemEditor },
	props: {
		buttonText: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		validateValue: {
			type: Function,
			default: defaultValidator,
		},
		isInputVisibleOnAddClick: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, context) {
		const {
			handleInput,
			showInput,
			hideInput,
			submitValue,
			isInputShown,
			inputValue,
			error,
		} = useEditableItemInput(props, context);

		return {
			handleInput,
			showInput,
			hideInput,
			submitValue,
			isInputShown,
			inputValue,
			error,
		};
	},
};
</script>

<style lang="scss" scoped>
@import './AddButton';

.add-button-input {
	margin-bottom: 1px;
}

// Position relative is needed if dropdown is passed as custom button
.add-button-container {
	position: relative;
}
</style>
